<header>
  <mat-toolbar>
    <a mat-icon-button href="/">
      <img
        ngSrc="assets/angular-work-logo.svg"
        width="24"
        height="24"
        alt="Angular.work logo"
        priority
      />
    </a>

    <a href="/" class="name-and-slogan"
      >Angular.work<span class="slogan">
        - Find Angular jobs, hire Angular developers</span
      ></a
    >

    <a
      mat-flat-button
      class="post-a-job-link"
      routerLink="/hire-angular-developers"
      >Post a job</a
    >
  </mat-toolbar>
</header>

<mat-divider class="toolbar-divider"></mat-divider>

<div class="main-container">
  <div class="fcontainer">
    <main class="fitem fitem-main"><router-outlet /></main>

    <aside class="fitem fitem-sidebar">
      <mat-card class="sidebar-card">
        <form
          #subscribeForm="ngForm"
          (ngSubmit)="subscribeForm.valid && submitForm(subscribeForm)"
        >
          <mat-card-header>
            <div mat-card-avatar class="header-image header-image-primary">
              <mat-icon
                aria-hidden="false"
                aria-label="email icon"
                fontIcon="email"
              ></mat-icon>
            </div>

            <mat-card-title>Angular developer?</mat-card-title>
            <mat-card-subtitle>Get new Angular jobs in:</mat-card-subtitle>
          </mat-card-header>

          <mat-card-content>
            <mat-form-field class="w-100">
              <input
                matInput
                type="email"
                required
                ngModel
                name="email"
                placeholder="your@email.com"
                email
                maxlength="250"
              />
              <mat-error>Type your email address</mat-error>
            </mat-form-field>

            <input type="text" ngModel name="_gotcha" class="gotcha" />
          </mat-card-content>

          <mat-card-actions>
            <button
              mat-stroked-button
              type="submit"
              class="w-100"
              [disabled]="isSubscribing"
            >
              Subscribe for free
            </button>
          </mat-card-actions>
        </form>

        @if (isSubscribing) {
          <mat-card-footer>
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </mat-card-footer>
        }
      </mat-card>

      <mat-card class="sidebar-card">
        <mat-card-header>
          <div mat-card-avatar class="header-image header-image-primary">
            <mat-icon
              aria-hidden="false"
              aria-label="work icon"
              fontIcon="work"
            ></mat-icon>
          </div>

          <mat-card-title>Hiring for Angular?</mat-card-title>
          <mat-card-subtitle>Post a job and get:</mat-card-subtitle>
        </mat-card-header>

        <mat-card-content>
          <ul class="value-list">
            <li>
              <mat-icon color="primary" fontIcon="star"></mat-icon>A featured
              job post for 30 days
            </li>
            <li>
              <mat-icon color="primary" fontIcon="email"></mat-icon>Your job
              post sent to our list of Angular developers
            </li>
            <li>
              <mat-icon color="primary" fontIcon="share"></mat-icon>Your job
              post shared on our social media
            </li>
            <li>
              <mat-icon color="primary" fontIcon="lock"></mat-icon>Secure
              payment with Stripe
            </li>
          </ul>
        </mat-card-content>

        <mat-card-actions>
          <a
            mat-flat-button
            routerLink="/hire-angular-developers"
            class="w-100"
          >
            Post a job for <s>€299</s> €199
          </a>
        </mat-card-actions>
      </mat-card>
    </aside>
  </div>
</div>

<footer class="footer">
  <mat-divider></mat-divider>
  <p>
    © 2024 Angular.work
    <a
      mat-icon-button
      href="https://www.facebook.com/angulardotwork"
      target="_blank"
    >
      <img
        ngSrc="assets/facebook-logo.svg"
        width="24"
        height="24"
        alt="Facebook logo"
      />
    </a>
    <a mat-icon-button href="https://x.com/angulardotwork" target="_blank">
      <img ngSrc="assets/x-logo.svg" width="24" height="24" alt="X logo" />
    </a>
  </p>
</footer>
