import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./job-list/job-list.component').then(
        (mod) => mod.JobListComponent,
      ),
    title: 'Angular.work - Angular job board',
  },
  {
    path: 'hire-angular-developers',
    loadComponent: () =>
      import('./post-a-job/post-a-job.component').then(
        (mod) => mod.PostAJobComponent,
      ),
    title: 'Hire Angular developers - Angular.work',
  },
  {
    path: 'success/:jobId',
    loadComponent: () =>
      import('./success/success.component').then((mod) => mod.SuccessComponent),
    title: 'Job posted! - Angular.work',
  },
  {
    path: 'angular-jobs/:slug',
    loadComponent: () =>
      import('./job-detail/job-detail.component').then(
        (mod) => mod.JobDetailComponent,
      ),
  },
  {
    path: 'page-not-found',
    loadComponent: () =>
      import('./page-not-found/page-not-found.component').then(
        (mod) => mod.PageNotFoundComponent,
      ),
    title: 'Page not found! - Angular.work',
  },
  {
    path: '**',
    loadComponent: () =>
      import('./page-not-found/page-not-found.component').then(
        (mod) => mod.PageNotFoundComponent,
      ),
    title: 'Page not found! - Angular.work',
  },
];
