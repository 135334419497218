import { Component, inject } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { MatToolbar } from '@angular/material/toolbar';
import { MatIconAnchor, MatAnchor, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import {
  MatCard,
  MatCardActions,
  MatCardAvatar,
  MatCardContent,
  MatCardFooter,
  MatCardHeader,
  MatCardSubtitle,
  MatCardTitle,
} from '@angular/material/card';
import { NgOptimizedImage } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { EMPTY, Subject, catchError, finalize, switchMap, tap } from 'rxjs';
import { FormsModule, NgForm } from '@angular/forms';
import { MatError, MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDivider } from '@angular/material/divider';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [
    RouterOutlet,
    MatToolbar,
    MatIconAnchor,
    MatIcon,
    MatAnchor,
    MatButton,
    RouterLink,
    MatCard,
    MatCardHeader,
    MatCardTitle,
    MatCardSubtitle,
    MatCardContent,
    MatCardActions,
    MatCardFooter,
    MatCardAvatar,
    FormsModule,
    MatFormField,
    MatInput,
    MatError,
    MatProgressBar,
    MatDivider,
    NgOptimizedImage,
  ],
})
export class AppComponent {
  #http = inject(HttpClient);
  #snackBar = inject(MatSnackBar);

  #submitSubject = new Subject<NgForm>();
  isSubscribing = false;

  #subscribeAction$ = this.#submitSubject.pipe(
    switchMap((subscribeForm) => this.#subscribeRequest(subscribeForm)),
    takeUntilDestroyed(),
  );

  constructor() {
    this.#subscribeAction$.subscribe();
  }

  #subscribeRequest(subscribeForm: NgForm) {
    this.isSubscribing = true;
    subscribeForm.form.disable();

    // TODO: `value` is any. Check if there's a way for the type to be inferred
    return this.#http.post('api/subscribe', subscribeForm.value).pipe(
      tap(() => {
        subscribeForm.resetForm();
        this.#snackBar.open(
          `Check your email to confirm your subscription!`,
          `OK`,
        );
      }),
      catchError((error) => {
        console.error(error);

        if (error instanceof HttpErrorResponse && error.status === 400) {
          this.#snackBar.open(
            `Invalid email address. Please type a real email address.`,
            `OK`,
          );
        } else {
          this.#snackBar.open(
            `Something went wrong. Please try again later.`,
            `OK`,
          );
        }

        return EMPTY; // Return an observable that just completes so errors don't break the stream
      }),
      finalize(() => {
        subscribeForm.form.enable();
        this.isSubscribing = false;
      }),
    );
  }

  submitForm(subscribeForm: NgForm) {
    this.#submitSubject.next(subscribeForm);
  }
}
